.NewsPageTitle {
  margin-top: 5rem; }
  .NewsPageTitle.display-4 {
    text-transform: none; }

.NewsList {
  margin: 0 auto;
  max-width: 840px; }
  .NewsList.row {
    margin: 0 auto; }
  .NewsList__title {
    text-align: center; }
    .NewsList__title.CardList__heading {
      margin: 5rem 0 3rem; }
  .NewsList__back {
    margin: 0rem 0 3rem;
    text-align: center;
    font-size: 0.75rem;
    font-weight: 500;
    opacity: 0.5;
    color: inherit; }
    @media (min-width: 960px) {
      .NewsList__back {
        position: absolute;
        top: 2.75rem;
        left: calc(50% - 450px);
        font-size: 1rem; } }
    @media (min-width: 1200px) {
      .NewsList__back {
        left: calc(50% - 600px); } }
  .NewsList__back-arrow {
    max-width: 18px;
    height: auto;
    margin-right: 0.8rem;
    transform: rotate(90deg); }
    @media (min-width: 960px) {
      .NewsList__back-arrow {
        max-width: 22px; } }
  .NewsList__read-more {
    margin-top: -3rem; }

.NewsItem {
  margin-bottom: 4rem;
  position: relative; }
  @media (min-width: 768px) {
    .NewsItem {
      display: flex;
      align-items: center; } }
  .NewsItem p.read-more {
    text-transform: uppercase;
    font-weight: 700;
    color: #000;
    transition: color 0.2s; }
  .NewsItem a {
    display: block;
    color: var(--gray-dark);
    transition: color 0.2s; }
  .NewsItem a:hover,
  .NewsItem a:hover > p.read-more {
    color: var(--teal);
    text-decoration: none; }

.NewsItem__image {
  margin-bottom: 2rem; }

@media (max-width: 768px) {
  .NewsItem__content.col-md-6 {
    padding: 0; } }

@media (min-width: 600px) {
  .NewsItem--fullWidth {
    margin-left: auto;
    margin-right: auto;
    width: 600px; } }
