.PageHeaderWithVideo {
  margin-bottom: 8rem;
  position: relative; }
  .PageHeaderWithVideo:after {
    position: absolute;
    bottom: 0;
    height: 50px;
    width: 50%;
    lefT: 25%;
    z-index: -1;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.2);
    filter: blur(17px);
    content: " "; }

.PageHeaderWithVideo__decorLine {
  height: 90px;
  position: absolute;
  bottom: -4em;
  left: calc(50% - 1px);
  content: " ";
  width: 5px;
  background-image: url(/img/divider-pink.svg);
  background-repeat: no-repeat; }

.PageHeaderWithVideo__videoContainer {
  margin: 0 auto;
  width: 90%;
  position: relative;
  overflow: hidden;
  background-color: #fff; }
  .PageHeaderWithVideo__videoContainer:after {
    content: " ";
    height: 0;
    width: 100%;
    display: block;
    padding-bottom: 56.1798%; }
  .PageHeaderWithVideo__videoContainer * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block; }
  .PageHeaderWithVideo__videoContainer video {
    max-width: 100%; }

.PageHeaderWithVideo__text {
  width: 50%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  align-items: center;
  display: flex; }
  .PageHeaderWithVideo__text h1 {
    margin-bottom: 2rem;
    font-size: 4rem; }
  .PageHeaderWithVideo__text.no-line:after {
    display: none; }

@media (max-width: 767.98px) {
  .PageHeaderWithVideo {
    width: 100%; }
    .PageHeaderWithVideo:after {
      display: none; }
    .PageHeaderWithVideo video {
      width: 100%; }
  .PageHeaderWithVideo__text {
    width: 95%;
    text-align: center; }
    .PageHeaderWithVideo__text h1 {
      margin-bottom: 2rem;
      font-size: 3rem; }
    .PageHeaderWithVideo__text h4 {
      text-align: center; } }
