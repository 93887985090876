.BlockPerson {
  margin-top: 2rem;
  margin-bottom: 6rem;
  display: flex;
  align-items: flex-start;
  position: relative;
  text-align: left; }
  .BlockPerson .BlockPerson__text {
    position: relative;
    max-height: 200em;
    overflow: hidden;
    transition: max-height 1s ease-in-out; }
    .BlockPerson .BlockPerson__text h5 {
      text-align: left; }
    .BlockPerson .BlockPerson__text.hide-overflow {
      max-height: 23em;
      padding-bottom: 2em; }
      .BlockPerson .BlockPerson__text.hide-overflow:after {
        position: absolute;
        bottom: 0;
        vertical-align: bottom;
        content: " ";
        text-align: center;
        width: 100%;
        height: 5em;
        background: linear-gradient(rgba(255, 255, 255, 0), white 70%); }
      .BlockPerson .BlockPerson__text.hide-overflow.no-overflow:after {
        display: none; }

.BlockPerson__moreButton {
  position: absolute;
  bottom: -20px;
  z-index: 1; }
  .BlockPerson__moreButton:hover {
    cursor: pointer;
    text-decoration: underline; }
  .BlockPerson__moreButton p {
    text-align: left;
    font-weight: 700;
    text-transform: uppercase; }

@media (max-width: 767.98px) {
  .BlockPerson__image {
    margin-bottom: 3rem; } }
