.CtaBadge {
  display: block;
  text-align: center;
  max-width: 240px;

  @media (min-width: 600px) {
    max-width: 300px;
  }

  margin: 0 auto 4rem;

  .frontpage & {
    position: absolute;
    top: 6rem;
    left: 50%;
    display: inline-block;

    transform: translateX(-50%);

    @media (min-width: 600px) {
      top: 9.5rem;
      // bottom: 2rem;
      // right: 2rem;
    }
  }

  .currentaffairspage & {
    display: none;
  }

  &__link {
    display: block;

    padding: 1rem 2rem 1rem 2rem;

    min-width: 200px;
    max-width: calc(100vw - 8rem);

    background: #5f5f5f;
    color: #fff;

    &:hover {
      color: #fff;
    }

    @media (min-width: 600px) {
      padding: 1.5rem 2.5rem 1.5rem 2rem;
      max-width: 300px;
    }
  }

  &__text {
    margin: 0;

    font-size: 13px;
    font-weight: 500;
    line-height: 1.4;
    @media (min-width: 600px) {
      font-size: 16px;
    }
  }

  &__button {
    display: inline-block;

    padding: 0.4rem 1.2rem;

    font-size: 11px;
    text-transform: uppercase;
    font-weight: 500;
    white-space: nowrap;

    border: 1px solid;

    @media (min-width: 600px) {
      padding: 0.5rem 1.6rem;
    }
  }

  &__text + &__button {
    margin-top: 0.5rem;

    @media (min-width: 600px) {
      margin-top: 0.75rem;
    }
  }

  &__close {
    position: absolute;
    top: 0.1rem;
    right: 0.5rem;

    color: #fff;
    border: none;

    background: transparent;
  }
}
