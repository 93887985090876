.BlockLink {
  margin-bottom: 10rem;
  display: flex;
  align-items: flex-end;
  position: relative;
  padding-bottom: 2rem;
  padding-top: 2rem; }
  .BlockLink p {
    color: #000; }
  .BlockLink:hover {
    text-decoration: none; }
    .BlockLink:hover .read-more {
      text-decoration: underline; }

.BlockLink__image:hover > .BlockLink__image:after {
  right: -30%; }

.BlockLink__text p {
  font-weight: 200; }

.BlockLink__text > h2 {
  color: #0B96A8;
  margin-bottom: 1rem;
  font-size: 2.7rem; }

.BlockLink__text.order-md-last > h2 {
  color: #E8B951; }

.BlockLink__text .read-more {
  text-transform: uppercase;
  font-weight: 700;
  color: #000;
  margin-bottom: 0; }

.BlockLink__image {
  position: relative; }
  .BlockLink__image:after {
    width: 90%;
    padding-top: 90%;
    top: 20%;
    right: -70%;
    border: 1px solid #E8B951;
    content: " ";
    position: absolute;
    z-index: -1;
    transition: border-width 0.2s; }
  .BlockLink__image.order-md-last:after {
    left: -70%;
    border: 1px solid #0B96A8; }

@media (max-width: 767.98px) {
  .BlockLink__text {
    margin-bottom: 2rem; } }
