.BlockTextWithImage {
  padding-bottom: 10rem; }
  .BlockTextWithImage .col-md-4 {
    display: flex;
    align-items: center; }
  .BlockTextWithImage .inner p:last-child {
    margin-bottom: 0; }
  .BlockTextWithImage h3:nth-of-type(1) {
    color: #0B96A8;
    margin-top: 0; }
  .BlockTextWithImage h3:nth-of-type(2) {
    color: #9ABDD1; }
  .BlockTextWithImage h3:nth-of-type(3) {
    color: #59598D; }
  .BlockTextWithImage h3:nth-of-type(4) {
    color: #E8B951; }
  .BlockTextWithImage h3:nth-of-type(5) {
    color: #C6467B; }
  .BlockTextWithImage h3:nth-of-type(6) {
    color: #0B96A8; }
  .BlockTextWithImage .BlockTextWithImage__text {
    text-align: left;
    display: flex;
    align-items: center; }
    .BlockTextWithImage .BlockTextWithImage__text h3 {
      margin-top: 2rem; }
    .BlockTextWithImage .BlockTextWithImage__text p, .BlockTextWithImage .BlockTextWithImage__text h2, .BlockTextWithImage .BlockTextWithImage__text h3, .BlockTextWithImage .BlockTextWithImage__text h4 {
      text-align: left; }
    .BlockTextWithImage .BlockTextWithImage__text p:last-child {
      margin-bottom: 0; }

.BlockTextWithImage__image {
  width: 100%; }
  .BlockTextWithImage__image.ratioContainer {
    width: 100%; }
  .BlockTextWithImage__image:after {
    width: 90%;
    padding-top: 90%;
    top: 20%;
    right: 20%;
    border: 1px solid #C6467B;
    content: " ";
    position: absolute;
    z-index: -1; }

@media (max-width: 767.98px) {
  .BlockTextWithImage__image {
    margin-bottom: 5rem; } }
