.Navbar__container {
  width: 95%;
  margin: 0 auto;
  display: flex; }

.navbar {
  background-color: #fff; }

.navbar-brand img {
  max-height: 60px; }

.Navbar__collapse.show {
  height: 100vh; }

.nav-item {
  padding-left: 0.3rem;
  padding-right: 0.3rem; }

a.nav-link {
  font-size: 0.8em;
  color: #313131 !important;
  position: relative;
  white-space: nowrap; }

a.nav-link.active:after,
a.nav-link:hover:after {
  border-bottom: 6px solid #E9ADB4;
  position: absolute;
  content: ' ';
  width: 100%;
  left: 0;
  bottom: 12px;
  z-index: -1; }

a.nav-link--search:after,
a.nav-link--search:hover:after,
a.nav-link--search.active:after {
  bottom: 0; }

a.nav-link.no-hover:hover:after {
  display: none; }

.navbar-nav {
  display: flex;
  align-items: center; }

.nav-link {
  text-align: center;
  text-transform: uppercase;
  font-weight: 700; }

.nav-link:hover {
  cursor: pointer; }

.nav-link--logo {
  max-width: 120px;
  margin-left: auto;
  margin-right: auto; }
  .nav-link--logo img {
    max-width: 100%; }

.navbar__search {
  position: absolute;
  right: 5rem;
  top: 1rem; }

@media (max-width: 1200px) {
  a.nav-link.active:after {
    width: 40%;
    left: 30%; }
  a.nav-link:hover:after {
    display: none; }
  .navbar {
    height: 58px;
    display: block !important;
    transition: height 0.5s ease-in-out;
    padding-left: 0 !important;
    padding-right: 0 !important;
    overflow: hidden; }
  .navbar-brand img {
    max-height: 45px; }
  .navbar.show {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }
  .navbar ul {
    display: block !important;
    width: 100%; }
  .navbar ul li {
    display: block;
    padding: 0;
    padding: 0.5rem 0;
    max-width: 300px;
    margin: auto; }
  .Navbar__container {
    display: block;
    margin: 0 auto; }
  .navbar-toggler {
    position: fixed;
    top: 10px;
    right: 10px;
    border: 0 !important; }
  .nav-item.push-down {
    margin-top: 1rem; } }
