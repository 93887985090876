.CardList__heading {
  text-align: center; }

.CardList__entries {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0; }

.CardList__entry, .CardList__border {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media (min-width: 600px) {
    .CardList__entry, .CardList__border {
      min-height: 250px; } }

.CardList__entry {
  width: 100%;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  list-style: none; }
  @media (min-width: 600px) {
    .CardList__entry {
      width: 50%;
      max-width: none;
      margin-left: 0;
      margin-right: 0; } }
  @media (min-width: 960px) {
    .CardList__entry {
      width: 33.33%; } }

.CardList__border {
  position: relative;
  align-items: center;
  flex-grow: 1;
  margin: 1rem 0 0;
  padding: 2.2rem 2rem 2.5rem;
  color: inherit;
  text-align: center;
  border: 1px solid #137b4d; }
  @media (min-width: 600px) {
    .CardList__border {
      margin: 1.5rem;
      padding: 2.2rem 2rem 2.5rem; } }
  @media (hover: hover) {
    .CardList__border {
      transition: background-color 0.2s, color 0.2s; }
      .CardList__border .Button {
        transition: background-color 0.2s, color 0.2s, border-color 0.2s; }
      .CardList__border:hover {
        color: #fff;
        background-color: #137b4d;
        text-decoration: none; }
        .CardList__border:hover .CardList__title {
          text-decoration: underline; }
        .CardList__border:hover .CardList__arrow {
          color: inherit; }
        .CardList__border:hover .Button {
          background: inherit;
          border-color: #fff;
          color: #fff; } }

.CardList__title-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 67.2px; }
  .CardList__title-container:after {
    content: '';
    display: block;
    margin: 1.5rem auto;
    width: 3rem;
    height: 1px;
    background-color: currentColor;
    border-radius: 2px;
    opacity: 0.3; }
    @media (min-width: 800px) {
      .CardList__title-container:after {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%); } }

.CardList__title {
  margin: 0; }

.CardList__read-more {
  position: relative;
  margin-bottom: 0;
  opacity: 0; }

.CardList__arrow {
  position: absolute;
  left: 50%;
  bottom: -1rem;
  transform: translateX(-50%) rotate(-90deg) scale(0.45);
  color: #137b4d; }

.CardList--center .CardList__entries {
  justify-content: center; }
