.BlockDocument {
  display: block;
  margin: 1rem 0 2rem;
  border: 1px solid #000;
  padding: 1rem;
  position: relative; }
  .BlockDocument p, .BlockDocument h5 {
    color: #000; }
  .BlockDocument .text {
    display: block;
    margin-left: 15%; }
  .BlockDocument:after {
    content: " ";
    top: 1rem;
    width: 10%;
    position: absolute;
    padding-bottom: 10%;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMzIgMzIiIGhlaWdodD0iMzJweCIgaWQ9InN2ZzIiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDMyIDMyIiB3aWR0aD0iMzJweCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOmlua3NjYXBlPSJodHRwOi8vd3d3Lmlua3NjYXBlLm9yZy9uYW1lc3BhY2VzL2lua3NjYXBlIiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiIHhtbG5zOnNvZGlwb2RpPSJodHRwOi8vc29kaXBvZGkuc291cmNlZm9yZ2UubmV0L0RURC9zb2RpcG9kaS0wLmR0ZCIgeG1sbnM6c3ZnPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgaWQ9ImJhY2tncm91bmQiPjxyZWN0IGZpbGw9Im5vbmUiIGhlaWdodD0iMzIiIHdpZHRoPSIzMiIvPjwvZz48ZyBpZD0iZG9jdW1lbnRfeDVGX3RleHQiPjxwb2x5Z29uIHBvaW50cz0iNCwyMiA0LDIwIDE2LDIwIDE2LDIyIDQsMjIgICIvPjxwb2x5Z29uIHBvaW50cz0iNCwxNCA0LDEyIDIwLDEyIDIwLDE0IDQsMTQgICIvPjxwb2x5Z29uIHBvaW50cz0iNCwxOCA0LDE2IDIwLDE2IDIwLDE4IDQsMTggICIvPjxwYXRoIGQ9Ik0xOC40MTQsMEgwdjMyaDI0VjUuNTg0TDE4LjQxNCwweiBNMTcuOTk4LDIuNDEzTDIxLjU4Niw2aC0zLjU4OFYyLjQxM3ogTTIsMzBWMS45OThoMTR2Ni4wMDFoNlYzMEgyeiIvPjwvZz48L3N2Zz4=); }
