@import 'variables';

.Timeline {
  overflow-y: visible;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  // margin: 0 0 -2rem;
  padding: 0 0 6rem;

  text-align: center;

  @media (min-width: 1025px) {
    padding: 0 0 7rem;
    margin-bottom: 1rem;

    &::-webkit-scrollbar {
      width: 12px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.02);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.06);
    }
  }

  &__entries {
    display: inline-block;

    padding: 0 100px;

    text-align: center;
    list-style: none;

    white-space: nowrap;
  }

  &__entry {
    position: relative;

    display: inline-block;
    width: 75px;

    text-align: center;

    @media (min-width: 960px) {
      width: 100px;
    }

    &:after {
      content: '';
      position: absolute;

      top: 30px;
      left: 0;
      right: 0;

      display: block;

      height: 3px;

      background: #e8e8e8;
    }

    &:first-child:after {
      border-radius: 3px 0 0 3px;
    }
    &:last-child:after {
      border-radius: 0 3px 3px 0;
    }
  }

  &__date {
    margin: 0;
    padding: 0 0 1rem;

    font-size: 12px;

    &:before,
    &:after {
      position: absolute;
      z-index: 1;

      left: 50%;
      top: 30px + 1px;

      display: block;

      width: 20px;
      height: 20px;

      border-radius: 50%;

      background-color: #e8e8e8;
      content: '';

      transform: translate(-50%, -50%);
    }

    &:after {
      border: 1px solid #2f2f2f;
      padding: 8px;
      transform: translate(-50%, -50%) scale(0.6);

      opacity: 0.2;

      transition: opacity 0.4s;

      .Timeline__entry--open & {
        opacity: 1;
      }
    }
  }

  &__link {
    color: inherit;
  }

  &__text {
    position: absolute;
    top: 100%;
    left: 50%;

    // display: none;

    margin: 30px 0 0 -110px;
    padding: 0.65rem 1rem;

    width: 220px;

    font-size: 14px;
    line-height: 1.4;
    font-weight: normal;
    text-align: left;
    white-space: normal;

    border: 1px solid #d0d0d0;

    background: #fff;

    transform-origin: 50% 15px;
    transform: translate3d(0, -6px, 0);
    opacity: 0;

    pointer-events: none;

    transition: transform 0.45s, opacity 0.45s;

    strong {
      white-space: nowrap;
    }

    .Timeline__entry--open & {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      pointer-events: all;
    }

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 0px;

      display: block;

      width: 16px;
      height: 16px;

      border-left: 1px solid #d0d0d0;
      border-top: 1px solid #d0d0d0;

      background: #fff;

      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}
