.MiscPage .BlockContent h2 {
  text-align: center;
  font-weight: 700;
  font-size: 3rem;
  margin-bottom: 3em;
  text-transform: uppercase;
  position: relative; }
  .MiscPage .BlockContent h2:after {
    height: 90px;
    position: absolute;
    bottom: -2em;
    left: calc(50% - 1px);
    content: " ";
    width: 5px;
    background-image: url(/img/divider-pink.svg); }

.MiscPage h1, .MiscPage h2, .MiscPage h3, .MiscPage h4, .MiscPage h5, .MiscPage h6 {
  text-align: center; }
