.CurrentAffairsPage {
  .PageHeader__heading,
  .CardList__heading {
    margin: 0 auto;

    width: 100%;

    font-size: 1.75rem;

    @media (min-width: 960px) {
      margin: 0.25rem auto 0;
      font-size: 2.5rem;
    }
  }

  .CardList {
    padding: 6rem 0 0;

    @media (min-width: 960px) {
      padding: 6rem 0 0;
    }
  }
}
