.SearchPage {
  margin-bottom: 6rem;

  input {
    max-width: 100%;

    text-align: center;
    border: 1px solid #eaeaea;

    &:focus {
      outline: 0;
      border-color: #ddd;
    }
  }
}

.SearchPage__results-label {
  margin: 1rem 0 0;
  padding: 0 0 0.75rem;

  font-size: 1rem;
  text-align: center;
  text-transform: none;
}

.SearchPage__results {
  min-height: 300px;
}
