.Tabs {
  position: relative;

  margin: 2rem 0 3rem;
  padding: 0;

  text-align: center;

  list-style: none;

  @media (min-width: 960px) {
    margin: 2rem 0 3rem;

    // li {
    //   min-width: 16rem;
    //   &:last-child {
    //     text-align: left;
    //   }
    // }
  }

  li {
    display: inline-block;

    @media (min-width: 960px) {
      min-width: 270px;

      &:first-child {
        text-align: right;
      }

      &:last-child {
        text-align: left;
      }
    }
  }

  a {
    display: block;

    margin: 0 2rem;

    font-size: 20px;

    color: inherit;

    &.active {
      font-weight: bold;
      text-decoration: underline;
    }
  }
}
