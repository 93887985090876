.BlockGroup {
  margin-bottom: 12rem;
  position: relative; }
  .BlockGroup:after {
    height: 90px;
    position: absolute;
    bottom: -9em;
    left: calc(50% - 1px);
    content: " ";
    width: 5px;
    background-image: url(/img/divider-blue.svg); }
  .BlockGroup h3, .BlockGroup .BlockGroup__buttonContainer {
    text-align: center; }
