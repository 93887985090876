.PageHeader {
  min-height: 60vh;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  background-size: cover;
  background-position: center center;
  width: 95%;
  margin: 0 auto;
  margin-bottom: 8rem; }
  .PageHeader:after {
    height: 90px;
    position: absolute;
    bottom: -4em;
    left: calc(50% - 1px);
    content: ' ';
    width: 5px;
    background-image: url(/img/divider-pink.svg); }

.PageHeader__backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0.6; }

.PageHeader--noImage :after {
  display: none; }

.PageHeader--plain {
  margin: 4rem auto 0;
  min-height: 0; }
  .PageHeader--plain:after {
    display: none; }

.PageHeader__text {
  padding: 1rem;
  position: relative;
  line-height: 1.5rem;
  max-width: 80% !important; }

.PageHeader__heading {
  margin: 2rem auto;
  font-size: 4rem;
  width: 80%;
  text-align: center; }

.PageHeader__lead {
  font-weight: 100;
  text-align: center;
  width: 80%;
  margin: 0 auto; }

.PageHeader__image {
  background-color: pink;
  height: 100%;
  background: url(http://www.make-scents.org/wp-content/uploads/2015/01/1-500x500.jpg);
  background-size: cover;
  background-position: center center; }

@media (max-width: 767.98px) {
  .PageHeader__text {
    max-width: 100% !important; }
  .PageHeader__heading {
    margin-bottom: 2rem;
    font-size: 3rem; } }
