.SocialLinks {
  position: fixed;
  right: 65px;
  width: 30px;
  top: 40vh;
  z-index: 100; }
  .currentaffairspage .SocialLinks {
    top: auto;
    bottom: 100px; }

.SocialLinks li {
  list-style-type: none;
  margin-bottom: 10px; }

.SocialLinks li img {
  width: 30px;
  height: 30px; }

@media (max-width: 767.98px) {
  .SocialLinks {
    display: none; } }
