body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  padding-top: 66px; }

.ratio-box {
  position: relative;
  display: block;
  width: 100%;
  /* padding-bottom is calculated and rendered in to HTML */ }
  .ratio-box--small {
    max-width: 250px; }

.ratio-box img,
.ratio-box iframe,
.ratio-box video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block; }
