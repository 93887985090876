.BlockTextTwoColumns {
  min-height: 22rem !important;
  margin-bottom: 10rem;
  display: flex;
  position: relative;
  align-items: center;
  text-align: right !important; }
  @media (max-width: 767.98px) {
    .BlockTextTwoColumns {
      text-align: left !important; } }
  .BlockTextTwoColumns p {
    font-weight: 200; }
  .BlockTextTwoColumns:after {
    width: 30%;
    padding-top: 30%;
    top: 10%;
    left: 35%;
    border: 1px solid #ddd;
    content: " ";
    position: absolute;
    z-index: -1; }
    @media (max-width: 767.98px) {
      .BlockTextTwoColumns:after {
        width: 100%;
        padding-top: 100%;
        top: 25%;
        left: 0; } }
  .BlockTextTwoColumns h3 {
    margin-top: 2rem;
    text-align: right !important; }
    @media (max-width: 767.98px) {
      .BlockTextTwoColumns h3 {
        text-align: left !important; } }
  .BlockTextTwoColumns h3:nth-of-type(1) {
    color: #0B96A8;
    margin-top: 0; }
  .BlockTextTwoColumns h3:nth-of-type(2) {
    color: #59598D; }
  .BlockTextTwoColumns h3:nth-of-type(3) {
    color: #E8B951; }
  .BlockTextTwoColumns .is-right {
    text-align: left !important; }
    .BlockTextTwoColumns .is-right h3 {
      text-align: left !important; }
    .BlockTextTwoColumns .is-right h3:nth-of-type(1) {
      color: #9ABDD1;
      margin-top: 0; }
      @media (max-width: 767.98px) {
        .BlockTextTwoColumns .is-right h3:nth-of-type(1) {
          margin-top: 1rem; } }
    .BlockTextTwoColumns .is-right h3:nth-of-type(2) {
      color: #C6467B; }
    .BlockTextTwoColumns .is-right h3:nth-of-type(3) {
      color: #0B96A8; }

@media (max-width: 767.98px) {
  .BlockTextTwoColumns .inner {
    column-count: 1; } }
